import HomePageBgAllInOne from "src/ui/assets/images/home-page-bg-all-in-one.webp";
import HomePageBgHWJ from "src/ui/assets/images/home-page-bg-hwj.webp";
import HomePageBgPrevention from "src/ui/assets/images/home-page-bg-prevention.webp";

import { TaskResponse, UpdateTaskRequest } from "@9amhealth/openapi";
import { Blac, Cubit } from "blac-next";
import {
  AppPopup,
  AppQueryPopupsController
} from "components/AppQueryPopups/AppQueryPopupsBloc";
import { OpenBrowser } from "src/hybrid/components/Browser";
import { DateFormats, dateLocal } from "src/lib/date";
import { featureFlags } from "src/lib/featureFlags";
import { getSupportedUserLanguage } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import {
  appViewState,
  appointmentState,
  taskManagementState,
  toast,
  userState
} from "src/state/state";
import {
  TaskKey,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import { TranslationKey } from "src/types/translationKey";
import {
  Step,
  StepStatus,
  Variables
} from "src/ui/components/StepList/StepList";
import { CustomQuestionnaireResult } from "../QuestionnaireCubit/QuestionnaireState";
import status = UpdateTaskRequest.status;

export interface OnboardingBlocState {
  loading: boolean;
  ctaText: TranslationKey;
  steps: Step[];
  title?: TranslationKey;
  backgroundImage?: string;
  variables?: Variables;
}

export type VisitType = "DIETITIAN_VISIT" | "PHARMACIST_VISIT" | "SYNC_VISIT";
type LabOrderProvider = "GETLABS" | "LABCORP"; // openapi LabOrderProviderType

const onboardingCompletedTask = {
  program: "onboarding",
  group: "user-experience",
  slug: "completed-onboarding"
};

export default class OnboardingBloc extends Cubit<OnboardingBlocState> {
  constructor() {
    super({
      loading: false,
      ctaText: "continue",
      steps: []
    });
    void this.init();
  }

  init = async () => {
    this.checkProgramMembership();
    this.updateSteps();
  };

  get labQuestionnaireTask() {
    return taskManagementState.getTask(TaskKey.INITIAL_LAB_QUESTIONNAIRE);
  }
  get labOrderTask() {
    return taskManagementState.getTask(TaskKey.COMPLETE_INITIAL_LAB_ORDER);
  }
  get uploadLabsTask() {
    return taskManagementState.getTask(TaskKey.UPLOAD_LABS);
  }
  get initialSyncVisitTask() {
    return taskManagementState.getTask(TaskKey.INITIAL_SYNC_VISIT);
  }

  /** Update the subtitle of the get your labs done step */
  subtitleStepGetYourLabsDone = (): TranslationKey | undefined => {
    const labStepStatus = this.statusStepGetYourLabsDone();
    const labInformation = this.checkLabsAppointment();

    if (
      labStepStatus === StepStatus.IN_PROGRESS &&
      labInformation.provider === "GETLABS" &&
      labInformation.appointmentId
    ) {
      const appointment = appointmentState.appointments.filter(
        (app) => app.id === labInformation.appointmentId
      )[0];

      if (appointment) {
        const userLanguage = getSupportedUserLanguage();
        const { start, end } = appointment ?? {};
        const weekday = dateLocal(start, { locale: userLanguage }).format(
          DateFormats.DISPLAY_WEEKDAY_MONTH_DAY
        );

        const timeZone = dateLocal(start, { locale: userLanguage }).format("z");
        const startTime = dateLocal(start, { locale: userLanguage }).format(
          DateFormats.DISPLAY_TIME_ALT
        );
        const endTime = dateLocal(end, { locale: userLanguage }).format(
          DateFormats.DISPLAY_TIME_ALT
        );

        this.patch({
          variables: {
            weekday,
            startTime,
            endTime,
            timeZone
          }
        });
        return "getlabs.eventDateTime";
      }
    }

    if (labStepStatus === StepStatus.PENDING) {
      return "onboardingSteps.subtitle.medicalReview";
    }
    return undefined;
  };

  /** Update the title of the schedule first visit step */
  titleStepScheduleFirstVisit = (): TranslationKey => {
    const task = this.initialSyncVisitTask;
    const visitType = this.visitTypeScheduleFirstVisit(task);
    const schedulingDisabled =
      this.checkSchedulingDisabledFeatureFlag(visitType);

    // if there's no visit type or flag is disabled, show Start Journey
    if (!visitType || schedulingDisabled) {
      return "onboardingSteps.step.startJourney";
    }

    return "onboardingSteps.step.scheduleFirstVisit";
  };

  /** Update the steps based on the current state */
  updateSteps = () => {
    const accountSetupStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const firstVisitStatus = this.statusStepScheduleFirstVisit();

    const labsStatusShown =
      accountSetupStatus === StepStatus.COMPLETED
        ? labsStatus
        : StepStatus.LOCKED;

    const firstVisitStatusShown =
      labsStatusShown === StepStatus.COMPLETED
        ? firstVisitStatus
        : StepStatus.LOCKED;

    const firstVisitTitle = this.titleStepScheduleFirstVisit();

    const steps: Step[] = [
      {
        status: StepStatus.COMPLETED,
        title: "onboardingSteps.step.completeRegistration"
      },
      {
        status: accountSetupStatus,
        title: "onboardingSteps.step.finishAccountSetup"
      },
      {
        status: labsStatusShown,
        title: "onboardingSteps.step.getYourLabsDone",
        subtitle: this.subtitleStepGetYourLabsDone()
      },
      {
        status: firstVisitStatusShown,
        title: firstVisitTitle
      }
    ];

    this.patch({ steps, ctaText: this.getCtaText() });
  };

  checkLabsAppointment = (): {
    provider?: LabOrderProvider;
    appointmentId?: string;
    schedulingUrl?: string;
    requisitionFileId?: string;
  } => {
    const { labOrderTask } = this;
    return {
      provider: (labOrderTask?.additionalData?.provider ||
        labOrderTask?.additionalData?.labOrderProvider) as
        | LabOrderProvider
        | undefined,
      appointmentId: labOrderTask?.additionalData?.appointmentId as
        | string
        | undefined,
      schedulingUrl: labOrderTask?.additionalData?.appointmentUrl as
        | string
        | undefined,
      requisitionFileId: labOrderTask?.additionalData?.requisitionFileId as
        | string
        | undefined
    };
  };

  /** Check if the task status is final */
  isTaskStatusFinal = (task: TaskResponseKnown | undefined): boolean => {
    return taskManagementState.isTaskStatusFinal(task);
  };

  /** Get the CTA text based on the current state */
  getCtaText = (): TranslationKey => {
    const accountSetupStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const labTask = taskManagementState.getTask(
      TaskKey.COMPLETE_INITIAL_LAB_ORDER
    );
    const visitStatus = this.statusStepScheduleFirstVisit();

    // labs
    if (labTask?.status === TaskResponse.status.IN_PROGRESS) {
      const { provider, appointmentId, requisitionFileId } =
        this.checkLabsAppointment();

      if (provider === "GETLABS" && appointmentId) {
        return "button.seeAppointmentDetails";
      }

      if (provider === "GETLABS" && !appointmentId) {
        return "button.scheduleAtHomeLabs";
      }

      if (requisitionFileId) {
        return "button.seeInstructions";
      }
    }

    if (labsStatus === StepStatus.PENDING) {
      const { labOrderTask, labQuestionnaireTask } = this;
      if (
        this.isTaskStatusFinal(labQuestionnaireTask) &&
        (labOrderTask?.status === TaskResponse.status.LOCKED ||
          labOrderTask?.status === TaskResponse.status.AVAILABLE)
      ) {
        return "button.contactCareTeam";
      }
    }

    // initial visit cta text
    if (
      accountSetupStatus === StepStatus.COMPLETED &&
      labsStatus === StepStatus.COMPLETED &&
      visitStatus === StepStatus.IN_PROGRESS
    ) {
      const task = this.initialSyncVisitTask;
      const type = this.visitTypeScheduleFirstVisit(task);
      const schedulingDisabled = this.checkSchedulingDisabledFeatureFlag(type);

      // if there's no visit type or flag is disabled, show Complete Onboarding
      if (!type || schedulingDisabled) {
        return "button.completeOnboarding";
      }

      return "button.scheduleNow";
    }

    return "button.continue";
  };

  /** Open the scheduler for the first visit */
  openScheduler = () => {
    const task = this.initialSyncVisitTask;
    // get the scheduling URL from the task, scheduling_url is the new field, popup_url is the old one before we updated the tasks
    const url =
      task?.additionalData?.scheduling_url?.toString() ??
      task?.additionalData?.popup_url?.toString();

    if (!url) {
      toast.error("error.noSchedulingUrl");
      reportErrorSentry(new Error("No scheduling URL found"));
      return;
    }

    AppQueryPopupsController.openPopup(AppPopup.iframe, {
      additionalParameters: {
        url,
        title: translate("onboardingSteps.step.scheduleFirstVisit"),
        stay: "false"
      },
      onEvent: {
        popupClosed: () =>
          void taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING)
      }
    });
  };

  /* Check if the scheduling is disabled for certain visit types */
  checkSchedulingDisabledFeatureFlag = (type?: VisitType): boolean => {
    switch (type) {
      case "DIETITIAN_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_rd"
        );

      case "PHARMACIST_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_pharmacist"
        );

      case "SYNC_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_md"
        );

      default:
        return false;
    }
  };

  static onboardingTasksToShow = [
    TaskKey.PHARMACY_INSURANCE,
    TaskKey.CKECKIN_QUESTIONNAIRE,
    TaskKey.SELECT_PCP
  ];

  /** Get the status of the account setup step */
  statusStepAccountSetup = (): StepStatus => {
    const tasks = OnboardingBloc.onboardingTasksToShow
      .map((task) =>
        taskManagementState.state.tasks.find(
          (t) => t.program === KnownProgram.ONBOARDING && t.slug === task
        )
      )
      .filter((task) => task);

    const allInFinalState = tasks.every(this.isTaskStatusFinal);

    if (allInFinalState) {
      return StepStatus.COMPLETED;
    }

    return StepStatus.IN_PROGRESS;
  };

  /** Get the status of the get your labs done a step */
  statusStepGetYourLabsDone = (): StepStatus => {
    const { labQuestionnaireTask, labOrderTask, uploadLabsTask } = this;

    // if q. is final and labs status is final, user did everything and user does not need to do labs
    if (
      this.isTaskStatusFinal(labQuestionnaireTask) &&
      this.isTaskStatusFinal(labOrderTask)
    ) {
      return StepStatus.COMPLETED;
    }

    // if q. is final, and labs status is locked we are waiting for connective
    // if q. is final, and labs status is available, waiting for req. form upload from care team
    if (
      this.isTaskStatusFinal(labQuestionnaireTask) &&
      this.isTaskStatusFinal(uploadLabsTask) &&
      (labOrderTask?.status === TaskResponse.status.LOCKED ||
        labOrderTask?.status === TaskResponse.status.AVAILABLE)
    ) {
      return StepStatus.PENDING;
    }

    // if the questionnaire is in progress, the step is in progress
    // or if the questionnaire is done, and the lab order is not started, the step is in progress
    if (this.statusStepAccountSetup() === StepStatus.COMPLETED) {
      return StepStatus.IN_PROGRESS;
    }

    return StepStatus.LOCKED;
  };

  /** Get the visit type of the schedule first visit step */
  visitTypeScheduleFirstVisit = (
    task: TaskResponseKnown | undefined
  ): VisitType | undefined => {
    // visit is unavailable for the user if the status is locked or skipped
    if (task?.status === TaskResponse.status.SKIPPED) {
      return undefined;
    }

    const taskType = task?.additionalData?.appointment_type;
    if (taskType) {
      return taskType as VisitType;
    }
    // fallback for old tasks that don't have the appointment type
    return "SYNC_VISIT";
  };

  /** Get the status of the schedule first visit step */
  statusStepScheduleFirstVisit = (): StepStatus => {
    // if the get your labs done a step is not completed, the step is not available
    if (this.statusStepGetYourLabsDone() !== StepStatus.COMPLETED) {
      return StepStatus.LOCKED;
    }

    return StepStatus.IN_PROGRESS;
  };

  /** Check the user's program membership and update the title and background image */
  checkProgramMembership = () => {
    const { programMemberships } = userState;
    const activeProgram = programMemberships?.find(
      (program) => program.active || (!program.start && !program.end)
    );

    switch (activeProgram?.program) {
      case ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY:
        this.patch({
          title: "onboardingSteps.title.healthyWeightJourney",
          backgroundImage: HomePageBgHWJ
        });
        break;

      case ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION:
        this.patch({
          title: "program.title_DIABETES_AND_HEART_DISEASE_PREVENTION",
          backgroundImage: HomePageBgPrevention
        });
        break;

      default:
        this.patch({
          title: "program.title_ALLINONE_HEALTHCARE_CONCIERGE",
          backgroundImage: HomePageBgAllInOne
        });
        break;
    }
  };

  /** Check if the user has access to the fast pass
   * If the user has a BMI < 30, they have access to the fast pass
   * */
  checkFastPassAccess = async (): Promise<boolean> => {
    try {
      // load weight and height, calculate bmi
      const bloc = Blac.getBloc(LabResultsCubit);
      await bloc.loadObservations();

      const obs = bloc.getSpecificObservations(["29463-7", "8302-2"]);

      // calculate bmi
      const weight = obs.find((o) => o.code.coding?.[0].code === "29463-7")
        ?.valueQuantity?.value;

      const height = obs.find((o) => o.code.coding?.[0].code === "8302-2")
        ?.valueQuantity?.value;

      if (!weight || !height) {
        return false;
      }

      const bmi = weight / (height / 100) ** 2;

      return bmi < 30;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    return false;
  };

  /** Skip the initial sync visit task */
  skipInitialSyncVisitTask = async () => {
    try {
      const task = this.initialSyncVisitTask;

      if (!task) {
        reportErrorSentry(
          new Error("Initial sync visit task is required to skip it")
        );

        return;
      }

      await taskManagementState.updateTaskStatus(
        {
          program: task.program,
          group: task.group,
          slug: task.slug
        },
        status.SKIPPED
      );
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /** Set the onboarding completed task to in-progress */
  setOnboardingCompletedTaskStarted = async () => {
    try {
      await taskManagementState.updateTaskStatus(
        onboardingCompletedTask,
        status.COMPLETED
      );
      appViewState.setShowOnboardingScreen(false);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /** Handle the CTA press for the schedule first visit step */
  ctaActionStepScheduleFirstVisit = () => {
    const task = this.initialSyncVisitTask;
    const type = this.visitTypeScheduleFirstVisit(task);

    const schedulingDisabled = this.checkSchedulingDisabledFeatureFlag(type);
    void this.setOnboardingCompletedTaskStarted();

    // if there's no visit type or flag is disabled, skip the task
    if (type && schedulingDisabled) {
      void this.skipInitialSyncVisitTask();
      return;
    }

    if (!type) {
      return;
    }

    if (["PHARMACIST_VISIT", "SYNC_VISIT"].includes(type)) {
      this.openScheduler();
      return;
    }

    void this.checkFastPassAccess().then((access) => {
      if (access) {
        AppQueryPopupsController.openPopup(AppPopup.fastPass, {
          additionalParameters: {
            stay: "false"
          },
          onEvent: {
            "skip-visit": () => {
              void this.skipInitialSyncVisitTask();
              AppQueryPopupsController.closePopup();
            },
            "schedule-visit": () => {
              console.log("schedule visit");
              this.openScheduler();
            }
          }
        });
        return;
      }

      this.openScheduler();
    });
  };

  /** Handle the CTA press for the account setup step */
  ctaActionStepAccountSetup = () => {
    AppQueryPopupsController.openPopup(AppPopup.onboardingTasks);
  };

  /** Handle CTA for initial lab order */
  ctaActionStepGetYourLabsDone = () => {
    const { labOrderTask, uploadLabsTask, labQuestionnaireTask } = this;
    const { appointmentId, requisitionFileId, provider, schedulingUrl } =
      this.checkLabsAppointment();

    if (labOrderTask?.status === TaskResponse.status.IN_PROGRESS) {
      if (appointmentId) {
        AppQueryPopupsController.openPopup(AppPopup.appointment, {
          additionalParameters: {
            id: appointmentId
          }
        });
        return;
      } else if (requisitionFileId && provider === "LABCORP") {
        AppQueryPopupsController.openPopup(AppPopup.labcorp, {
          additionalParameters: {
            fileId: requisitionFileId
          }
        });
        return;
      } else if (provider === "GETLABS" && schedulingUrl && !appointmentId) {
        void OpenBrowser(schedulingUrl, {
          presentationStyle: "popover",
          useBaseUrl: false
        });
        return;
      }
    }

    // if the questionnaire task is not final, open the Questionnaire
    if (
      !this.isTaskStatusFinal(labQuestionnaireTask) ||
      !this.isTaskStatusFinal(uploadLabsTask)
    ) {
      AppQueryPopupsController.openPopup(AppPopup.initialLab);
      return;
    }

    appViewState.navigate("/app/chat");
  };

  /** Handle the CTA press
   * Depending on the status of the steps, do different things
   * */
  handleCtaPress = () => {
    const setupAccountStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const scheduleFirstVisitStatus = this.statusStepScheduleFirstVisit();
    //
    // POST SIGN UP Tasks
    if (setupAccountStatus === StepStatus.IN_PROGRESS) {
      this.ctaActionStepAccountSetup();
      return;
    }

    // LABS
    if (
      labsStatus === StepStatus.IN_PROGRESS ||
      labsStatus === StepStatus.PENDING
    ) {
      this.ctaActionStepGetYourLabsDone();
      return;
    }

    // FIRST VISIT
    if (scheduleFirstVisitStatus === StepStatus.IN_PROGRESS) {
      this.ctaActionStepScheduleFirstVisit();
      return;
    }
  };

  /** Check if the user selected the other provider option in the initial lab questionnaire */
  checkUserSelectdOtherProvider = (
    data: CustomQuestionnaireResult
  ): boolean => {
    const PROVIDER_QUESTION_ID = "lXCczllBjyLh";
    const PROVIDER_CHOICE_OTHER_ID = "TAeIktkwrrHe";
    const userSelectedOtherProvider = data.answers.json.find((answer) => {
      const questionMatch = answer.questionId === PROVIDER_QUESTION_ID;
      if (
        questionMatch &&
        typeof answer.fieldValue === "object" &&
        "choiceId" in answer.fieldValue
      ) {
        return answer.fieldValue.choiceId === PROVIDER_CHOICE_OTHER_ID;
      }
      return false;
    });

    return Boolean(userSelectedOtherProvider);
  };

  /** When user answers the initial lab questionnaire, we should update the upload_lab_report task */
  handleInitialLabQuestionnaireCompleted = async (
    data: CustomQuestionnaireResult
  ) => {
    // update tasks from the server
    await taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING);
    const { uploadLabsTask, labOrderTask } = this;

    // do nothihng if tasks are not found
    if (!uploadLabsTask) {
      return;
    }

    const uploadAlreadyFinal = this.isTaskStatusFinal(uploadLabsTask);
    const completeLabOrderAlreadyFinal = this.isTaskStatusFinal(labOrderTask);

    // do nothing if the upload or complete-lab task is already completed/skipped
    if (uploadAlreadyFinal || completeLabOrderAlreadyFinal) {
      return;
    }

    const userSelectedOtherProvider = this.checkUserSelectdOtherProvider(data);

    await taskManagementState.updateTaskStatus(
      uploadLabsTask,
      userSelectedOtherProvider ? status.IN_PROGRESS : status.SKIPPED
    );
  };
}

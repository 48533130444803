import styled from "@emotion/styled";
import React, { FC } from "react";
import translate from "src/lib/translate";
import { TranslationKey } from "src/types/translationKey";
import PendingGIF from "src/ui/assets/images/pending.gif";

//#region Styled components
const StepWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 0.75rem;

  /* Add gray connecting line to end of step status icon */
  &::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: calc(1rem - 0.5px);
    width: 1px;
    height: calc(100% - 2rem);
    background: var(--color-gray-lighter, #e6e3db);
  }

  /* Add sunrise connecting line to end of step status icon for steps that are not followed by 'LOCKED' step */
  &:not(:has(+ [data-status="LOCKED"])) {
    &::before {
      background: var(
        --Sunrise-Vertical-Regular,
        linear-gradient(1deg, #80aeff 13.49%, #f7bde6 51.22%, #ffbd70 85.11%)
      );
    }
  }

  /* Hide connecting line on last step */
  &:last-of-type {
    &::before {
      display: none;
    }
  }

  &:not([data-status="LOCKED"]) {
    /* Add sunrise background and border to steps that are not 'LOCKED' */
    > div:first-of-type {
      background: var(
        --Sunrise-Vertical-Regular,
        linear-gradient(181deg, #80aeff 13.49%, #f7bde6 51.22%, #ffbd70 85.11%)
      );

      &::before {
        background: var(
          --Sunrise-Vertical-Lightest,
          linear-gradient(
            181deg,
            #c1d7fb 13.49%,
            #fddee9 50.42%,
            #ffdfb4 85.11%
          )
        );
      }
    }
  }

  &[data-status="IN_PROGRESS"] {
    /* Add circle icon to 'IN_PROGRESS' steps */
    > div:first-of-type {
      &::after {
        content: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><circle cx="9" cy="9" r="8.8" fill="url(%23a)"/><defs><linearGradient id="a" x1="9" x2="9" y1="17.8" y2=".2" gradientUnits="userSpaceOnUse"><stop stop-color="%230E3763"/><stop offset=".526" stop-color="%235B4855"/><stop offset="1" stop-color="%238A6947"/></linearGradient></defs></svg>');
      }
    }

    p,
    small {
      color: var(--color-charcoal, #212121);
    }
  }

  &[data-status="PENDING"] {
    /* Add three dots gif icon to 'PENDING' steps */
    > div:first-of-type {
      &::after {
        content: "";
        background: url(${PendingGIF});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    p,
    small {
      color: var(--color-charcoal, #212121);
    }
  }

  &[data-status="COMPLETED"] {
    /* Add checkmark icon to 'COMPLETED' steps */
    > div:first-of-type {
      &::after {
        content: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path fill="url(%23a)" stroke="url(%23b)" stroke-linejoin="round" d="M6.965 13.4 3.133 9.57l.569-.55 3.263 3.263 6.93-6.948.587.568-7.517 7.499Z"/><defs><linearGradient id="a" x1="3.133" x2="14.482" y1="9.367" y2="9.367" gradientUnits="userSpaceOnUse"><stop stop-color="%230E3763"/><stop offset=".526" stop-color="%235B4855"/><stop offset="1" stop-color="%238A6947"/></linearGradient><linearGradient id="b" x1="3.133" x2="14.482" y1="9.367" y2="9.367" gradientUnits="userSpaceOnUse"><stop stop-color="%230E3763"/><stop offset=".526" stop-color="%235B4855"/><stop offset="1" stop-color="%238A6947"/></linearGradient></defs></svg>');
      }
    }

    p,
    small {
      color: var(--color-charcoal-60, rgba(33, 33, 33, 0.6));
      text-decoration: line-through;
    }
  }
`;

const StepStatusIcon = styled.div`
  position: relative;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--color-gray-lighter, #e6e3db);

  &::before {
    content: "";
    position: absolute;
    inset: 1px;
    border-radius: 50%;
    background: #fff;
  }

  &::after {
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StepTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0.3125rem 0 1.25rem;
`;

const StepTitle = styled.p`
  font-weight: 500;
  color: var(--color-charcoal-80, rgba(33, 33, 33, 0.8));
  margin: 0;
`;
//#endregion

export enum StepStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  LOCKED = "LOCKED"
}

export type Step = {
  status: StepStatus;
  title: TranslationKey;
  subtitle?: TranslationKey;
};

export interface Variables {
  weekday?: string;
  startTime?: string;
  endTime?: string;
  timeZone?: string;
}

type StepListProps = {
  steps: Step[];
  variables?: Variables;
};

const StepList: FC<StepListProps> = ({ steps, variables }) => {
  return (
    <div>
      {steps.map((step) => (
        <StepWrapper key={step.title} data-status={step.status}>
          <StepStatusIcon></StepStatusIcon>
          <StepTextWrapper>
            <StepTitle>{translate(step.title)}</StepTitle>
            {step.subtitle && (
              <small>
                {translate(step.subtitle, variables as Record<string, unknown>)}
              </small>
            )}
          </StepTextWrapper>
        </StepWrapper>
      ))}
    </div>
  );
};

export default StepList;
